
import HintTooltip from '@/components/helper/HintTooltip.vue'
import { Tenant } from '@/store/modules/app/types'
import { AppConst } from '@/store/modules/constants'
import {
  JigMetaData,
  JigShareRequest,
  MyJig,
  SharedJigItem,
  TenantJigPermissions,
} from '@/store/modules/jig/types'
import { Namespace, StandardObject } from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { segmentEventTracking } from '@/utils/tracking'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

declare var consoleLog: any

@Component({
  components: {
    'hint-tooltip': HintTooltip,
  },
})

export default class ShareableLinkVue extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  public jig!: StandardObject
  @Prop({ type: String, default: AppConst.JigVisibilities.Link })
  public jigVisibility!: AppConst.JigVisibilities
  @Prop({ type: String, default: '' })
  public itemDeeplink!: AppConst.JigVisibilities
  @Prop({ type: Boolean, default: false })
  public deeplinkURLRequesting!: boolean
  @Prop({ type: Boolean, default: false })
  public showButtonText!: boolean
  @Prop({ type: Boolean, default: false })
  public disabled!: boolean
  @Prop({ type: Boolean, default: true })
  public readonlyJig!: boolean
  @Prop({ type: Number, default: TenantHelpers.InvalidTenantID })
  public myTenantID!: number
  @Prop({ type: Boolean, default: false })
  public isSuperUserOrJigStaff!: boolean

  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('jigMetadata', { namespace: Namespace.Jig })
  public jigMetadata!: JigMetaData
  @State('saveJigVisibilityResult', { namespace: Namespace.Jig })
  public saveJigVisibilityResult!: any

  @Action('requestJigDeeplinkURL', { namespace: Namespace.Jig })
  public requestJigDeeplinkURL: any

  public $refs!: Vue['$refs'] & {
    hintTooltip: HintTooltip,
  }

  private clipboardSnackbarVisible: boolean = false
  private latestClipboardEntry: string = ''
  private latestClipboardTitle: string = 'Link copied to clipboard!'
  private isClipboardCopySuccess: boolean = false
  private deeplink: string = this.itemDeeplink
  private isRequestingQRCode: boolean = false
  // `public` visibility behaviour falls back to `link`
  private activeVisibility: AppConst.JigVisibilities = this.jigVisibility !== AppConst.JigVisibilities.Public ? this.jigVisibility : AppConst.JigVisibilities.Link
  private configList: {
    [K in AppConst.JigVisibilities]?: {
      icon: string
      text: string
    }
  } = {
    [AppConst.JigVisibilities.Link]: {
      icon: 'Icon_Globe.svg',
      text: AppConst.jigVisibilities[AppConst.JigVisibilities.Link],
    },
    [AppConst.JigVisibilities.Private]: {
      icon: 'Icon_Shield.svg',
      text: AppConst.jigVisibilities[AppConst.JigVisibilities.Private],
    },
    [AppConst.JigVisibilities.Password]: {
      icon: 'Icon_Lock_light.svg',
      text: AppConst.jigVisibilities[AppConst.JigVisibilities.Password],
    },
  }
  private copyConfigList: {
    [K in AppConst.JigVisibilities]?: {
      icon: string
      text: string
    }
  } = {
    [AppConst.JigVisibilities.Link]: {
      icon: 'Icon_Link.svg',
      text: 'Copy link only',
    },
    [AppConst.JigVisibilities.Password]: {
      icon: 'Icon_Mail.svg',
      text: 'Copy link and password',
    },
  }
  private isConfigSelectActive: boolean = false
  private isCopyConfigSelectActive: boolean = false

  @Watch('itemDeeplink', { immediate: true })
  onItemDeeplinkChanged(val: string) {
    this.deeplink = val
  }

  @Watch('jigVisibility', { immediate: true })
  onJigVisibilityChanged(val: AppConst.JigVisibilities) {
    if (val === AppConst.JigVisibilities.Public) {
      this.activeVisibility = AppConst.JigVisibilities.Link
    } else {
      this.activeVisibility = val
    }
  }

  private get canEditJig(): boolean {
    const jig = this.jigMetadata

    if (jig === null || !jig.RequestUserPermission || jig.RequestUserPermission.CRUD === null) {
      return false
    }

    return jig.RequestUserPermission.CRUD.U === true && !this.readonlyJig
  }

  private get myJigItem(): MyJig {
    return this.jig as MyJig
  }

  private get jigMetadataItem(): JigMetaData {
    return this.jig as JigMetaData
  }

  private get isOwner(): boolean {
    return (this.myJigItem.author || this.jigMetadataItem.Author) === this.$auth0.user.email
  }

  private get isSharedWithTeam(): boolean {
    // if the RequestUserPermission.ID == -1 or 0, and the RequestUserPermission.CRUD is null or RequestUserPermission.CRUD.R == true then the Jig was shared via team access settings.
    if (this.myTenantID === TenantHelpers.InvalidTenantID) {
      return false
    }

    const teamSharePermissions = this.jig.Tenants && this.jig.Tenants.find((t: TenantJigPermissions) => t.TenantID === this.myTenantID)

    return teamSharePermissions && teamSharePermissions.Permissions && teamSharePermissions.Permissions.includes(AppConst.SharePermission.teamPermissionView)
  }

  private get isFreeTier(): boolean {
    return this.myTenant.Subscription.TierType === AppConst.Subscription.subscriptionTierFree
  }

  private get isJigPasswordAlreadySet() {
    return !!this.jigMetadata.Password
  }

  private get sharedItem(): SharedJigItem {
    // When ShareableLink is imported in JigsListing page
    // Jig data returns as MyJig type
    // When ShareableLink is imported in JigDetails page
    // Jig data returns as JigMetaData type
    // So we need to utilise the data into the same type.

    return {
      id: this.myJigItem.id || this.jigMetadataItem.Id,
      name: this.myJigItem.name || this.jigMetadataItem.ProjectName,
      author: this.myJigItem.author || this.jigMetadataItem.Author,
      thumbnail: this.myJigItem.thumbnailURL || this.jigMetadataItem.ThumbnailURL,
      deeplinkUrl: this.myJigItem.deeplinkURL || this.jigMetadataItem.DeeplinkURL || '',
      visibility: this.myJigItem.visibility || this.jigMetadataItem.ProjectVisibility,
      isOwner: this.isOwner,
      teamShared: this.isSharedWithTeam,
      isStaffOwned: this.isSuperUserOrJigStaff,
    }
  }

  private get jigDeeplinkURL(): string {
    let deeplinkVal: string = ''

    if (this.myJigItem.deeplinkURL && this.myJigItem.deeplinkURL !== '') {
      deeplinkVal = this.myJigItem.deeplinkURL as string
    }

    if (this.jigMetadataItem.DeeplinkURL && this.jigMetadataItem.DeeplinkURL !== '') {
      deeplinkVal = this.jigMetadataItem.DeeplinkURL as string
    }

    return deeplinkVal
  }

  private get defaultCopyContent(): string {
    return this.jigDeeplinkURL
  }

  private get eventAction(): string {
    if (this.$route.path.startsWith('/jig-detail')) {
      return 'JigDetail'
    } else if (this.$route.name === '/my-jigs') {
      if (this.$route.meta && this.$route.meta.teamJigView) {
        return 'TeamJigList'
      } else {
        return 'JigList'
      }
    }
    return ''
  }

  private isLinkPassword(): boolean {
    return this.activeVisibility === AppConst.JigVisibilities.Password
  }

  private activeConfigText(): string {
    return (this.configList[this.activeVisibility] as StandardObject).text
  }

  private isVisibilityAvailableForTenant(visibility: string) {
    switch (visibility) {
      case AppConst.JigVisibilities.Private:
        return this.myTenant.Subscription.AllowPrivateJigs
      case AppConst.JigVisibilities.Password:
        return this.myTenant.Subscription.AllowPasswordJigs
      default:
        return true
    }
  }

  private stringNilOrEmpty(v: string): boolean {
    return (v === undefined || v === null || v.length === 0)
  }

  private emitSuccess(activity?: string) {
    // clipboardSnackbarVisible === true means it's copying the share link
    // if it's false it's a QR code request
    this.shareableLinkSegmentTracking(
      this.clipboardSnackbarVisible ? 'JigShared' : 'QRCodeSharingQR',
      activity || 'JigSharedViaShareableLink'
    )

    const payload: any = {
      isClipboardCopySuccess: this.isClipboardCopySuccess,
      latestClipboardEntry: this.latestClipboardEntry,
      latestClipboardTitle: this.latestClipboardTitle,
      clipboardSnackbarVisible: this.clipboardSnackbarVisible,
    }

    this.$emit('on-copy-succeeded', payload)
  }

  private shareableLinkSegmentTracking(event: string, activity: string) {
    const jig: SharedJigItem = this.sharedItem

    segmentEventTracking(
      event,
      {
        action: this.eventAction,
        jigId: jig.id,
        jigName: jig.name,
        jigOwner: `${jig.author}${jig.isStaffOwned ? ' (JigStaff)' : ''}`,
        jigThumbail: jig.thumbnail.indexOf('jig-placeholder-color') > -1 ? '' : jig.thumbnail,
        jigUrl: jig.deeplinkUrl,
        jigVisibility: jig.visibility,
        isJigOwner: jig.isOwner,
        teamShared: jig.teamShared,
        sharingActivity: activity,
      }
    )
  }

  private vClipboardResultHandler({ value, event, activity }: any, isSuccess: boolean, showSuccessMessage: boolean = true) {
    if (event && event.target && event.target.type === 'text') {
      if (!!value) {
        // Select text field content to show content is selected and copied
        event.target.select()
      } else {
        // As we cannot disable v-clipboard function, so manually checking if text field is empty (which means deeplink is empty) when it's clicked. If there's no deeplink do not show success message.
        isSuccess = false
        showSuccessMessage = false
      }
    }

    this.isClipboardCopySuccess = isSuccess
    this.latestClipboardEntry = value
    this.clipboardSnackbarVisible = showSuccessMessage

    this.emitSuccess(activity)
  }

  private copyJigDeeplinkUrlToClipboard(showSuccessMessage: boolean = true, activity?: string) {
    if (!!this.jigDeeplinkURL) {
      this.deeplink = this.jigDeeplinkURL
      this.writeToClipboard(this.defaultCopyContent, showSuccessMessage, activity)

      return true
    }

    return false
  }

  private triggerCopyShareableLinkPrompt(v: string, showSuccessMessage: boolean = true, activity?: string, event?: Event) {
    this.$emit('on-copy-shareable-link', {
      visibility: this.activeVisibility,
      data: {
        deeplink: v,
        showSuccessMessage,
        activity,
        event,
      },
    })
  }

  private async copyShareableLink(v: string, showSuccessMessage: boolean = true, activity?: string, event?: Event) {
    if (!this.stringNilOrEmpty(v) && !!this.jigDeeplinkURL) {
      await this.writeToClipboard(v, showSuccessMessage, activity, event)
    } else {
      await this.requestJigDeeplinkUrl(showSuccessMessage)
    }
  }

  private async writeToClipboard(v: string, showSuccessMessage: boolean = true, activity?: string, event?: Event) {
    if (v.length > 0) {
      window.navigator.clipboard.writeText(v).then(
        () => {
          // clipboard write succeeded
          if (event && event.target && (event.target as HTMLInputElement).type === 'text') {
            const target = event.target as HTMLInputElement
            target.select()
          }
          this.isClipboardCopySuccess = true
          this.latestClipboardEntry = v
          this.clipboardSnackbarVisible = showSuccessMessage
          this.emitSuccess(activity)
        },
        () => {
          // clipboard write failed
          this.isClipboardCopySuccess = false
          this.latestClipboardEntry = v
          this.clipboardSnackbarVisible = false
        }
      )
    }
  }

  private async requestJigDeeplinkUrl(showSuccessMessage: boolean = true) {
    let linkRequireResult = this.copyJigDeeplinkUrlToClipboard(showSuccessMessage)

    if (linkRequireResult) {
      return linkRequireResult
    }

    const req: JigShareRequest = {
      jig: this.jig as MyJig|JigMetaData,
      branchIOCustomData: {},
      targetApp: 'jigspace',
      route: 'JigDetails',
    }

    await this.requestJigDeeplinkURL(req)
    // Note: share links can be debugged by having a branch io account and adding ?debug=true
    // to the end of the URL
    // Also Branch IO responses may be intercepted by a browser ad blocker.

    if (this.jigDeeplinkURL === undefined || this.jigDeeplinkURL === '') {
      linkRequireResult = false as boolean
      consoleLog('The result Jig Share link was empty, something went wrong getting a share link.')
    } else {
      linkRequireResult = true as boolean
      this.copyJigDeeplinkUrlToClipboard(showSuccessMessage)
    }

    return linkRequireResult
  }

  private toggleSelectField(key: string) {
    if (!this.canEditJig && key !== 'copy') {
      return
    }

    switch (key) {
      case 'visibility':
        this.isConfigSelectActive = !this.isConfigSelectActive
        break
      case 'copy':
        this.isCopyConfigSelectActive = !this.isCopyConfigSelectActive
        break
      default:
        break
    }
  }

  private resetSelectField() {
    this.isConfigSelectActive = false
  }

  private updateShareConfig(config: any, visibility: AppConst.JigVisibilities) {
    if (!this.canEditJig) {
      return
    }

    this.toggleSelectField('visibility')

    if (visibility === AppConst.JigVisibilities.Private && this.isFreeTier) {
      // Free tier cannot set to private share.
      // Show warning prompt and return.
      this.$emit('on-private-share-warning-triggered', {
        promptKey: 'privateWarning',
        eventName: 'SettingsPrivateSharing_FeatureGateShown',
      })
      return
    } else if (visibility === AppConst.JigVisibilities.Password && !this.myTenant.Subscription.AllowPasswordJigs) {
      // Only Org tier can set to password share.
      // Show warning prompt for other tiers and return.
      this.$emit('on-password-share-warning-triggered', {
        promptKey: 'passwordWarning',
        eventName: 'SettingsPasswordSharing_FeatureGateShown',
      })
      return
    } else if (visibility === AppConst.JigVisibilities.Password && this.myTenant.Subscription.AllowPasswordJigs && this.isJigPasswordAlreadySet) {
      // Only Org tier can set to password share.
      // Show warning prompt for other tiers and return.
      this.$emit('on-password-regenerate-triggered', {
        promptKey: 'passwordRegenerate',
        visibility,
        callback: () => {
          this.$refs.hintTooltip.showHintTooltip()
        },
      })
      return
    }

    this.activeVisibility = visibility
    this.updateShareVisibility(visibility)
  }

  private updateShareVisibility(visibility: string) {
    this.$emit('on-updating-jig-visibility', {
      visibility,
      callback: () => {
        if (visibility === AppConst.JigVisibilities.Password) {
          this.$refs.hintTooltip.showHintTooltip()
        }
      },
    })
  }

  private updateCopyConfig(key: string) {
    if (key === AppConst.JigVisibilities.Password) {
      const copyContent = `You have been shared a private Jig, you'll need the password below:\n\n${this.sharedItem.name}\n${this.jigDeeplinkURL || this.jigMetadata.DeeplinkURL}\n\nPassword: ${this.jigMetadata.Password}`
      this.latestClipboardTitle = 'Link & password copied to clipboard!'

      this.triggerCopyShareableLinkPrompt(copyContent, true, 'JigSharedViaPasswordAndLink')
    } else {
      this.latestClipboardTitle = 'Link copied to clipboard!'
      this.triggerCopyShareableLinkPrompt(this.defaultCopyContent, true, 'JigSharedViaShareableLink')
    }

    this.toggleSelectField('copy')
  }

  private copyDeeplink(deeplink: string, event?: Event) {
    this.latestClipboardTitle = 'Link copied to clipboard!'
    this.triggerCopyShareableLinkPrompt(deeplink, true, 'JigSharedViaShareableLink', event)
  }

  private copyJigPassword() {
    this.latestClipboardTitle = 'Password copied to clipboard!'
    this.copyShareableLink(this.jigMetadata.Password || '', true, 'JigSharedViaPasswordOnly')
  }
}
