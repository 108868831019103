import { JigMetaData } from '@/store/modules/jig/types'
import { segmentEventTracking } from '@/utils/tracking'

export class JigTeamShareTracking {
  public static segmentEventTrackForJigTeamSharingSet(
    teamShareViewEnabled: boolean,
    teamShareViewDisabled: boolean,
    teamShareReshareEnabled: boolean,
    teamShareReshareDisabled: boolean,
    eventTracking: any,
    jigMetadata: JigMetaData
  ) {
    if (teamShareViewEnabled) {
      segmentEventTracking(
        'TeamSharingSet',
        {
          ...eventTracking,
          origin: 'Dashboard',
          state: true,
          jigId: jigMetadata.Id,
          jigName: jigMetadata.ProjectName,
        }
      )
      segmentEventTracking(
        'TeamSharingEnabled',
        {
          ...eventTracking,
          origin: 'Dashboard',
          jigId: jigMetadata.Id,
          jigName: jigMetadata.ProjectName,
        }
      )
    } else if (teamShareViewDisabled) {
      segmentEventTracking(
        'TeamSharingSet',
        {
          ...eventTracking,
          origin: 'Dashboard',
          state: false,
          jigId: jigMetadata.Id,
          jigName: jigMetadata.ProjectName,
        }
      )
      segmentEventTracking(
        'TeamSharingDisabled',
        {
          ...eventTracking,
          origin: 'Dashboard',
          jigId: jigMetadata.Id,
          jigName: jigMetadata.ProjectName,
        }
      )
    }

    if (teamShareReshareEnabled) {
      // If team resharing is enabled, send resharing tracking too.
      segmentEventTracking(
        'EnableResharingEnabled',
        {
          ...eventTracking,
          origin: 'Dashboard',
          jigId: jigMetadata.Id,
          jigName: jigMetadata.ProjectName,
        }
      )
    } else if (teamShareReshareDisabled) {
      segmentEventTracking(
        'EnableResharingDisabled',
        {
          ...eventTracking,
          origin: 'Dashboard',
          jigId: jigMetadata.Id,
          jigName: jigMetadata.ProjectName,
        }
      )
    }
  }
}
