
import { Tenant } from '@/store/modules/app/types'
import { AppConst } from '@/store/modules/constants'
import { EmbedQueryParamConfig, Namespace, StandardObject } from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import Axios from 'axios'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'

declare var consoleLog: any

@Component({})

export default class EmbedCodeTabVue extends Vue {
  @Prop({ type: Object, default: () => ({ jigId: '', jigName: '', jigDeeplink: '' }) })
  public jigData!: StandardObject
  @Prop({ type: String, default: '' })
  public tierType!: string

  @State('embedParamsConfig', { namespace: Namespace.Jig })
  public embedParamsConfig!: EmbedQueryParamConfig
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant

  @Mutation('updateEmbedParamsConfig', { namespace: Namespace.Jig })
  public updateEmbedParamsConfig: any

  /**
   * Some attributes, like `autoplay` can be set in jig itself, it might
   * be turned on by default but not reflected in iframe src url,
   * we need to make sure it's not overwritten on initial load.
   * We need to keep its default behaviour by default
   * so we won't overwrite it with `=1` or `=0`
   *
   * hasDefaultBehaviour: {
   *   autoplay: true,
   * }
   */
  // in param-config.json default jigId field name for v-modal bind is `jig`.
  private jig: string = ''
  private name: string = ''
  private deeplink: string = ''
  private defaultUrl: string = ''
  private defaultIframeEmbedHtml: string = ''
  private backlinkHtml: string = ''
  private queryParams: StandardObject = {}
  private isFieldFocused: StandardObject = {}
  private optionDependence: StandardObject = {}
  private selectedOptions: any[] = []
  private selectedOptionInputs: StandardObject = {}
  private inputErrors: StandardObject = {}
  private hasDefaultBehaviour: StandardObject = {
    autoplay: true,
  }
  private freeTierPreset: string[] = ['autoplay', 'stepdelay']
  private isLoadingConfig: boolean = true
  private isCopySuccessful: boolean = false

  private get embedQueries(): string {
    return this.selectedOptions.map((option) => `${option}=${this.selectedOptionInputs[option]}`).join('&')
  }

  @Watch('jigData', { immediate: true })
  private async onJigData(val: StandardObject, oldVal: StandardObject) {
    this.jig = val.jigId
    this.name = val.jigName
    this.deeplink = val.jigDeeplink

    if (val && (!oldVal || val.jigId !== oldVal.jigId)) {
      this.isCopySuccessful = false
      this.selectedOptions = []
      await this.initDataLoad()
    }
  }

  /**
   * Some Query parameters, like `autoplay`, are native settings of Jig.
   * They can be set in Jig itself (i.e. `autoplay` sits in JigMetadata).
   * This means though there's no `autoplay` query parameter, a Jig can still be autoplaying.
   * As a result we need to make sure no `autoplay` is attached
   * as a query parameter on initial load to make sure
   * Jig can behave its default behaviour.
   * Once this option has any change (check/unckeck), we start
   * to append the query parameter and overwrite the value.
   */
  private get embedSrcUrl(): string {
    const turnedOffParams = Object.keys(this.queryParams).filter(
      (ov) =>
        !this.selectedOptions.includes(ov) &&
        !Object.values(this.optionDependence).find((dep) => dep.includes(ov)) &&
        !Object.prototype.hasOwnProperty.call(this.hasDefaultBehaviour, ov)
    )
    const offParamString = turnedOffParams.map((param) => `&${param}=${this.queryParams[param].minValue}`).join('')

    return `${this.defaultUrl}?${this.embedQueries}${offParamString}`
  }

  private get isFreeTier(): boolean {
    return this.tierType === AppConst.Subscription.subscriptionTierFree
  }

  private get teamThemeColor(): string {
    return !this.isFreeTier && this.myTenant.ColorHex1 ? `#${this.myTenant.ColorHex1}` : `#${TenantHelpers.TenantDefaultColor1}`
  }

  protected async mounted() {
    await this.initDataLoad(true)
  }

  async initDataLoad(isInitialLoading = false) {
    await this.loadingConfig()
    this.prepareData()
    this.craftIframeEmbed({
      initialLoading: isInitialLoading,
    })
  }

  private async loadingConfig() {
    if ((!this.embedParamsConfig || !Object.keys(this.embedParamsConfig).length) && process.env.VUE_APP_EMBED_CONFIG_URL) {
      try {
        const result = await Axios.get(process.env.VUE_APP_EMBED_CONFIG_URL)

        if (result.status === 200) {
          const paramsConfig = result.data

          this.updateEmbedParamsConfig(paramsConfig)
        } else {
          throw new Error(result.data)
        }
      } catch (error) {
        consoleLog(`Error happened on requesting for query param config: ${error}`)
      }
    }

    const cleanHtmlString = this.cleanDefaultIframeEmbedHtml({
      defaultIframeHtml: this.embedParamsConfig.defaultIframeEmbedHtml,
      backlinkHtml: this.embedParamsConfig.backlinkHtml,
    })
    this.defaultIframeEmbedHtml = cleanHtmlString.cleanIframeString
    this.backlinkHtml = cleanHtmlString.cleanBacklinkString

    this.defaultUrl = this.cleanDefaultURL(this.embedParamsConfig.defaultUrl)

    this.embedParamsConfig.queryParamDocs[AppConst.Subscription.embedQueryParamDefaultLang].forEach((param: any) => {
      this.queryParams = {
        ...this.queryParams,
        [param.name]: param,
      }
    })

    this.isLoadingConfig = false
    this.$emit('configLoaded', {
      loading: this.isLoadingConfig,
      defaultIframeEmbedHtml: this.defaultIframeEmbedHtml,
      backlinkHtml: this.backlinkHtml,
    })
  }

  private cleanDefaultIframeEmbedHtml(data: {
    defaultIframeHtml: string
    backlinkHtml: string
  }) {
    const cleanIframeString = data.defaultIframeHtml
      .replace(/\\/g, '')
      .replace(/src="(.*?)"/g, 'src=""')
      .replace(/^<iframe(\s)/g, '<iframe\n\t')
      .replace(/(src="")\s/g, '$1\n\t')
      .replace(/></g, '\n><')

    const cleanBacklinkString = data.backlinkHtml
      .replace(/\\/g, '')
      .replace(/\[jigDeeplink\]/g, this.deeplink)
      .replace(/\[jigName\]/g, this.name)
      .replace(/\[jigId\]/g, this.jig)
      .replace(/\[brandColor\]/g, this.teamThemeColor)

    return {
      cleanIframeString,
      cleanBacklinkString,
    }
  }

  private cleanDefaultURL(url: string) {
    return url.replace(/\?.*/g, '')
  }

  private isInputRequired(type: string) {
    return type === 'number'
  }

  private resetData() {
    this.isCopySuccessful = false
    this.optionDependence = {}
    this.selectedOptions = []
    this.selectedOptionInputs = {}
    this.inputErrors = {}
  }

  private prepareData() {
    this.resetData()
    const validOptions = []

    for (const key in this.queryParams) {
      const option = this.queryParams[key]
      let queryValue = option.defaultValue

      /**
       * Set checkbox checked value here:
       *    checked box = parameter value of 1
       *    unchecked box = parameter value of 0
       * We need to make sure when toggle field is selected,
       * its value is always set to it's maximum value (1) not default value.
       * This is because default value might be 0,
       * it defines if checkbox is checked or not.
       * If we have checked value to be 0 we will
       * end up with `option=0` when checkbox is selected.
       */
      if (option.valueType === 'toggle') {
        queryValue = option.maxValue
      }

      if (key === 'jig' && this.jig) {
        queryValue = this.jig
      }

      this.selectedOptionInputs = {
        ...this.selectedOptionInputs,
        [key]: queryValue,
      }

      if (option.dependence) {
        this.optionDependence[key] = option.dependence
      }

      if (option.dependsOnParam) {
        const pramName = option.dependsOnParam.name

        if (!this.optionDependence[pramName]) {
          this.optionDependence[pramName] = []
        }

        if (!this.optionDependence[pramName].includes(key)) {
          this.optionDependence[pramName].push(key)
        }
      }

      if ((!option.prefillName || key === 'jig') && option.defaultValue) {
        if (!option.dependsOnParam) {
          validOptions.push(key)
        }

        if (!option.prefillName && this.isInputRequired(option.valueType)) {
          this.inputErrors[key] = {
            error: false,
            message: this.craftErrorMessage(option),
            valueType: option.valueType,
            defaultValue: option.defaultValue,
            minValue: option.minValue,
            maxValue: option.maxValue,
          }
        }
      }
    }

    if (!this.selectedOptions.length) {
      this.selectedOptions = [...this.selectedOptions, ...validOptions]
    }

    if (this.isFreeTier) {
      this.freeTierPreset.forEach((preset) => {
        if (!this.selectedOptions.includes(preset)) {
          this.selectedOptions.push(preset)
        }
      })
    }
  }

  private craftErrorMessage(option: StandardObject) {
    let extraMessage = ''

    switch (option.valueType) {
      case 'number':
        if (option.minValue && !option.maxValue) {
          // Only minValue provided
          extraMessage = ` greater than ${option.minValue - 1}`
        } else if (!option.minValue && option.maxValue) {
          // Only maxValue provided
          extraMessage = ` less than ${option.maxValue + 1}`
        } else if (option.minValue && option.maxValue) {
          extraMessage = ` between ${option.minValue} and ${option.maxValue}`
        }

        return `Please type in a number${extraMessage}`
      case 'string':
        return 'Please type in valid string'
      default:
        return 'Please type in a valid value'
    }
  }

  private focusField(name: string | number) {
    this.isFieldFocused = {
      ...this.isFieldFocused,
      [name]: true,
    }
  }

  private offFocusField(name: string | number) {
    this.isFieldFocused = {
      ...this.isFieldFocused,
      [name]: false,
    }
  }

  private isDisabled(dependsOnParam: any) {
    return (
      dependsOnParam &&
      ((dependsOnParam.value && !this.selectedOptions.includes(dependsOnParam.name)) ||
        (!dependsOnParam.value && this.selectedOptions.includes(dependsOnParam.name)))
    )
  }

  private inputAttributes(minValue: any, maxValue: any) {
    const attributes: StandardObject = {}

    if (minValue) {
      attributes.min = minValue
    }

    if (maxValue) {
      attributes.max = maxValue
    }

    return attributes
  }

  private validateInput(name: string | number) {
    this.isCopySuccessful = false
    let valid = true
    let inputValue

    // Once a paremeter has been modified, overwrite its default behaviour.
    if (this.hasDefaultBehaviour[name]) {
      delete this.hasDefaultBehaviour[name]
    }

    if (this.inputErrors[name]) {
      switch (this.inputErrors[name].valueType) {
        case 'number':
          inputValue = parseInt(this.selectedOptionInputs[name], 10)

          if (isNaN(inputValue)) {
            // When string value has been filled in, reset to default value
            valid = false
            this.selectedOptionInputs[name] = this.inputErrors[name].defaultValue
          } else if (inputValue < this.inputErrors[name].minValue) {
            // When it's less than its min value reset with minValue
            valid = false
            this.selectedOptionInputs[name] = this.inputErrors[name].minValue
          } else if (inputValue > this.inputErrors[name].maxValue) {
            // When it exceeds its max value reset with maxValue
            valid = false
            this.selectedOptionInputs[name] = this.inputErrors[name].maxValue
          } else {
            this.selectedOptionInputs[name] = inputValue
            valid = true
          }
          break
        default:
          break
      }

      this.inputErrors[name].error = !valid
    }

    if (valid) {
      this.craftIframeEmbed({
        name,
      })
    }
  }

  private craftIframeEmbed(option: StandardObject) {
    if (this.optionDependence[option.name] && !this.selectedOptions.includes(option.name)) {
      this.selectedOptions = [...this.selectedOptions.filter((opt) => !this.optionDependence[option.name].includes(opt))]
    }

    this.defaultIframeEmbedHtml = this.defaultIframeEmbedHtml.split(/src=".*"/).join(`src="${this.embedSrcUrl}"`)

    this.$emit('iframeEmbedUpdated', {
      defaultIframeEmbedHtml: this.defaultIframeEmbedHtml,
      backlinkHtml: this.backlinkHtml,
      initialLoading: option.initialLoading,
    })
  }

  private copyEmbedCode() {
    window.navigator.clipboard.writeText(`${this.defaultIframeEmbedHtml}\n${this.backlinkHtml}`).then(
      () => {
        this.isCopySuccessful = true
      },
      () => {
        // clipboard write failed
        this.isCopySuccessful = false
      }
    )
  }
}
