
import CalloutBanner from '@/components/share/CalloutBanner.vue'
import { JigTeamShareTracking } from '@/components/share/JigTeamShareTracking'
import ShareUsers from '@/components/share/ShareUsers.vue'
import { Tenant, TenantUser } from '@/store/modules/app/types'
import { AppConst } from '@/store/modules/constants'
import { JigMetaData } from '@/store/modules/jig/types'
import { Namespace, PermissionActions, StandardObject } from '@/store/types'
import { ListHelpers } from '@/utils/list-helpers'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { segmentEventTracking } from '@/utils/tracking'
import isEqual from 'lodash/isEqual'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

@Component({
  components: {
    'share-users': ShareUsers,
    'callout-banner': CalloutBanner,
  },
})

export default class PermissionTabVue extends Vue {
  @Prop({ type: Array, default: () => [] })
  public jigIds!: string[]
  @Prop({ type: Number, default: -1 })
  public jigOwnerUid!: number
  @Prop({ type: Function, default: () => {} })
  public permissionUpdate!: Function
  @Prop({ type: Function, default: () => {} })
  public permissionDelete!: Function
  @Prop({ type: Boolean, default: true })
  public readonlyJig!: boolean
  @Prop({ type: Boolean, default: true })
  public isLoadingConfig!: boolean

  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('tenantUsers', { namespace: Namespace.App })
  public tenantUsers!: TenantUser[]
  @State('jigMetadata', { namespace: Namespace.Jig })
  public jigMetadata!: JigMetaData
  @State('jigDetailPermissions', { namespace: Namespace.Jig })
  public jigDetailPermissions: any
  @State('apiError', { namespace: Namespace.Utils })
  public apiError!: string
  @State('createBulkResponse', { namespace: Namespace.Invites })
  public createBulkResponse!: StandardObject

  @Action('createBulk', { namespace: Namespace.Invites })
  public inviteCreateBulk: any

  private isFieldFocused: any ={}
  private myTenantIndex: number = 0
  private readonlyVue: boolean = true
  private teamSharePermissions: string[] = []
  private teamSharePermissionsViewValue: AppConst.SharePermission = AppConst.SharePermission.teamPermissionView
  private teamSharePermissionsReshareValue: AppConst.SharePermission = AppConst.SharePermission.teamPermissionReshare
  private resType: AppConst.PermissionsResourceType = AppConst.PermissionsResourceType.Jig
  private isConfigSelectActive: any = {}
  private jigOwnerPermission: any = {}
  private userWithJigPermissions: any[] = []
  private tenantUsersWithNoJigPermissions: any[] = []
  private isAdvancedPermissionActive: boolean = false
  private currentEditor: string = ''
  private editorToInvite: string = ''
  private viewersToInvite: string[] = []
  private failedInvites: any[] = []
  private myTenantID: number = -1
  private unknownIndex: number = -1

  @Watch('readonlyJig')
  private onReadonlyJigChanged(newVal: boolean) {
    this.readonlyVue = newVal
  }

  private get calloutCopy(): string {
    let copy = ''

    if (this.jigDetailPermissions && this.jigDetailPermissions.length > 1 && this.tenantUsers.length > 1 && !this.isMultiJigsShare) {
      copy = `This Jig is currently shared with ${this.jigDetailPermissions.length - 1} people`
    } else if (this.tenantUsers.length === 1) {
      copy = `Add team members to securely share ${!this.isMultiJigsShare ? 'this Jig' : `these ${this.jigIds.length} Jigs`} with them`
    } else if (this.isMultiJigsShare) {
      copy = `Share ${this.jigIds.length} Jigs with team members`
    } else {
      copy = 'This Jig isn\'t currently shared with anyone'
    }

    return this.isLoadingConfig ? '' : copy
  }

  private get calloutCtaCopy(): string {
    let copy = ''

    if (this.tenantUsers.length > 1) {
      copy = this.isJigManagable ? 'Manage access' : 'Who can see this Jig?'
    } else {
      copy = 'Add team members'
    }

    return copy
  }

  private get canEditJig(): boolean {
    let canEditJig = true

    if (!this.isMultiJigsShare) {
      const jig = this.jigMetadata

      if (jig === null || !jig.RequestUserPermission || jig.RequestUserPermission.CRUD === null) {
        return false
      }

      canEditJig = jig.RequestUserPermission.CRUD.U === true && !this.readonlyVue
    } else {
      canEditJig = this.isMultiJigsShare
    }

    return canEditJig
  }

  private get teamCanView(): boolean {
    return this.teamSharePermissions.includes(this.teamSharePermissionsViewValue)
  }

  private get teamCanReshare(): boolean {
    return this.teamSharePermissions.includes(this.teamSharePermissionsReshareValue)
  }

  private get orderedTenantUsers(): Array<any> {
    const allUsers = this.userWithJigPermissions.concat(this.tenantUsersWithNoJigPermissions)

    return allUsers
  }

  private get isMultiJigsShare(): boolean {
    return this.jigIds.length > 1
  }

  private get isJigManagable(): boolean {
    return this.isMultiJigsShare || this.isJigOwner()
  }

  private get canViewUserList(): boolean {
    return this.isJigOwner() || this.myTenant.ID === this.jigMetadata.OwnerTenantID
  }

  private get eventTracking(): any {
    return {
      action: this.$route.name ? this.$route.name.replace(/\s/g, '') : '',
    }
  }

  private get isOrgTier() {
    return this.myTenant.Subscription && this.myTenant.Subscription.TierType === AppConst.Subscription.subscriptionTierOrganization
  }

  private get isFreeTier() {
    return this.myTenant.Subscription && this.myTenant.Subscription.TierType === AppConst.Subscription.subscriptionTierFree
  }

  protected mounted() {
    // When included as dynamic component inside a deeper level child component,
    // i.e. child-component inside child-component,
    // $refs.permissionTab will be unavailable on modal activation.
    // In this case we need to let parent know when it's mounted/updated where $refs are updated.
    this.$emit('on-permission-tab-mounted')
  }

  protected updated() {
    this.$emit('on-permission-tab-updated')
  }

  private isJigOwner(uid?: number) {
    const jigUid = uid || this.jigMetadata.Uid
    return this.$auth0.uid === jigUid
  }

  private loadJigDetails(data: any) {
    const {
      myTenantID,
      resetPermissionTab,
      skipIndividualPermissionCheck,
    } = data
    this.readonlyVue = true
    if (myTenantID) {
      this.myTenantID = myTenantID
    }
    this.prepareUserList(resetPermissionTab, skipIndividualPermissionCheck)

    for (const p of this.jigDetailPermissions) {
      if (p.CRUD.R && p.CRUD.U && p.AudID === this.$auth0.uid) {
        this.readonlyVue = false
        break
      }
    }

    if (!this.isMultiJigsShare) {
      this.updateMyTenantIndex()

      this.teamSharePermissions = this.jigMetadata.Tenants && this.jigMetadata.Tenants[this.myTenantIndex] && this.jigMetadata.Tenants[this.myTenantIndex].Permissions ? this.jigMetadata.Tenants[this.myTenantIndex].Permissions : []
    }
  }

  private resetValues(resetPermissionTab = true) {
    this.currentEditor = ''
    this.jigOwnerPermission = {}
    this.userWithJigPermissions = []
    this.tenantUsersWithNoJigPermissions = []
    this.editorToInvite = ''
    this.viewersToInvite = []
    this.failedInvites = []

    if (resetPermissionTab) {
      this.isAdvancedPermissionActive = false
      this.isConfigSelectActive = {}
    }
  }

  private prepareUserList(resetPermissionTab = true, skipIndividualPermissionCheck = false) {
    this.resetValues(resetPermissionTab)
    this.jigOwnerPermission = ListHelpers.getItemById(this.jigDetailPermissions, 'AudID', this.jigOwnerUid)

    this.tenantUsers.forEach((user: any) => {
      const matchingUser = this.jigDetailPermissions.find((p: any) => p.AudName === user.email)

      delete user.CRUD
      delete user.jigPermissions
      user.isUpdatingPermission = false
      this.isConfigSelectActive[user.ID] = false

      if (!matchingUser) {
        // User with no permission to view/edit this Jig
        this.tenantUsersWithNoJigPermissions.push(user)
      } else {
        if (matchingUser.CRUD.U) {
          this.currentEditor = matchingUser.AudName
        }

        if (!skipIndividualPermissionCheck) {
          user.CRUD = matchingUser.CRUD
          user.jigPermissions = {
            ID: matchingUser.ID,
            ResID: matchingUser.ResID,
          }
        }

        if (user.Uid !== this.jigOwnerPermission.AudID && !skipIndividualPermissionCheck) {
          // None-owner user with view or edit permission of this Jig
          this.userWithJigPermissions.push(user)
        } else if (user.Uid === this.jigOwnerPermission.AudID && !skipIndividualPermissionCheck) {
          // Owner user with view or edit permission of this Jig
          this.userWithJigPermissions.unshift(user)
        } else if (user.Uid !== this.jigOwnerPermission.AudID) {
          this.tenantUsersWithNoJigPermissions.push(user)
        }
      }
    })

    // Append the users with Permissions that not belong to current Tenant
    if (!skipIndividualPermissionCheck && this.jigDetailPermissions.length > this.userWithJigPermissions.length) {
      this.jigDetailPermissions.forEach((p: any) => {
        if (!this.userWithJigPermissions.find((u: any) => u.email === p.AudName)) {
          const nonActiveTenantUser = {
            Uid: p.AudID,
            email: p.AudName,
            username: p.AudName,
            CRUD: p.CRUD,
            isUpdatingPermission: false,
            jigPermissions: {
              ID: p.ID,
              ResID: p.ResID,
            },
            roles: [] as Array<string>,
          }

          // Unity app use following code to check if user is Guest viewer.
          // public bool HasExpired()
          // {
          //     return Valid && DateTime.Compare(DateTime.Parse(Time), DateTime.Now) < 0
          // }
          if (p.ExpiresAt.Valid && new Date(p.ExpiresAt.Time).valueOf() < new Date().valueOf()) {
            nonActiveTenantUser.roles.push(TenantHelpers.RoleGuestViewer1)
          }

          this.userWithJigPermissions.push(nonActiveTenantUser)
        }
      })
    }
  }

  private triggerSharePanel() {
    this.isAdvancedPermissionActive = true
    if (this.isFreeTier) {
      segmentEventTracking('SharingAccessPanel_FeatureGateShown', {
        tenantName: this.myTenant.Name,
        tenantId: this.myTenant.ID,
        jigName: this.jigMetadata.ProjectName,
        jigId: this.jigMetadata.Id,
      })
    }
    this.$emit('on-advanced-permission-triggered')
  }

  private closeSharePanel() {
    this.isAdvancedPermissionActive = false
    this.isConfigSelectActive = {}
  }

  private focusField(name: string) {
    this.isFieldFocused = {
      ...this.isFieldFocused,
      [name]: true,
    }
  }

  private offFocusField(name: string) {
    this.isFieldFocused = {
      ...this.isFieldFocused,
      [name]: false,
    }
  }

  private onTeamCanViewChanged(value: any) {
    if (!value.includes(this.teamSharePermissionsViewValue)) {
      this.teamSharePermissions = []
    }

    if (this.$route.name === 'Jig detail') {
      this.$emit('on-update-jig-share-config', { permissionsDirty: true })
    }

    this.$emit('on-updating-jig-visibility', {
      teamSharePermissions: this.teamSharePermissions,
      myTenantIndex: this.myTenantIndex,
      callback: (payload: any) => {
        const { currentPermission, newTeamPermissions } = payload

        this.teamShareEventTrack(currentPermission, newTeamPermissions)
      },
    })
  }

  private updateMyTenantIndex() {
    // Jig details endpoint returns full list of Team Access permissions (fixed in EN-549), for easier access we need to record the myTenantID of matching Tenant's team access settings. Otherwise we will have to use Array.find all the time.
    if (!this.jigMetadata.Tenants || this.jigMetadata.Tenants.length === 0) {
      return undefined
    }

    let matchingIndex = this.jigMetadata.Tenants.findIndex((tenant: any) => tenant.TenantID === this.myTenantID && tenant.Type === 'sharing')

    // If current Tenant's team access permission already exists, save its index for easier access. Otherwise index should indicate the new index that new permission item can be assigned to in `.Tenants` array.
    if (matchingIndex >= 0) {
      this.myTenantIndex = matchingIndex
    } else {
      this.myTenantIndex = this.jigMetadata.Tenants.length
    }
  }

  // Specific event tracking when team sharing is updated.
  // We only track when:
  // 1. Team view is enabled/disabled
  // 2. Team re-share is enabled/disabled
  // When team view and re-share are enabled at the same time, send both events.
  // Team share setting toggles are disabled in multi-jig share modal,
  // hence no special treatments need to be done for multi-share situation.
  private teamShareEventTrack(currentPermission: any, newPermission: any) {
    let teamShareViewEnabled = false
    let teamShareReshareEnabled = false
    let teamShareViewDisabled = false
    let teamShareReshareDisabled = false
    // A brand new Jig might have no Tenants details at all, so might be undefined.
    // It also might have a Permissions but content is `undefined` or `null` instead of an empty string.
    // So we need to handle both situations to compare the values.
    // When team permission has been changed, we check status and decide what evebt tracking we need to push.
    const teamPermissionDontExist = !currentPermission || !currentPermission.Permissions

    if (
      teamPermissionDontExist && newPermission.Permissions.length ||
      currentPermission && currentPermission.Permissions && !isEqual(currentPermission.Permissions.sort(), newPermission.Permissions.sort())
    ) {
      teamShareViewEnabled =
        this.teamCanView &&
        (
          teamPermissionDontExist ||
          !currentPermission.Permissions.includes(this.teamSharePermissionsViewValue)
        )
      teamShareReshareEnabled =
        this.teamCanReshare &&
        (
          teamPermissionDontExist ||
          !currentPermission.Permissions.includes(this.teamSharePermissionsReshareValue)
        )
      teamShareViewDisabled =
        !this.teamCanView &&
        !teamPermissionDontExist &&
        currentPermission.Permissions.includes(this.teamSharePermissionsViewValue)
      teamShareReshareDisabled =
        !this.teamCanReshare &&
        !teamPermissionDontExist &&
        currentPermission.Permissions.includes(this.teamSharePermissionsReshareValue)
    }

    JigTeamShareTracking.segmentEventTrackForJigTeamSharingSet(
      teamShareViewEnabled,
      teamShareViewDisabled,
      teamShareReshareEnabled,
      teamShareReshareDisabled,
      this.eventTracking,
      this.jigMetadata
    )
  }

  private async deleteUserPermission(user: any) {
    const req = {
      PermID: user.jigPermissions.ID,
      ResID: user.jigPermissions.ResID,
    }
    this.$emit('on-update-jig-share-config', { permissionsDirty: true })
    await this.permissionDelete(req)

    if (this.apiError !== '') {
      this.$emit('on-permission-update-error', {
        isLoadingConfig: false,
      })
    }

    this.$emit('on-permission-update-callback', () => {
      this.loadJigDetails({ resetPermissionTab: false })

      this.$emit('on-delete-permission-successful', {
        isLoadingConfig: false,
      })
    })
  }

  private async updateEditor(user: any) {
    const pv = this.jigDetailPermissions.find((p: any) => p.AudName === user.email)

    if (pv) {
      pv.CRUD.U = true
      pv.CRUD.R = true

      const req = {
        Perm: pv,
        JigId: this.jigMetadata.Id,
      }

      this.$emit('on-update-jig-share-config', { permissionsDirty: true })
      await this.permissionUpdate(req)

      if (this.apiError !== '') {
        this.$emit('on-permission-update-error', {
          isLoadingConfig: false,
        })
      }

      this.$emit('on-permission-update-callback', () => {
        this.loadJigDetails({ resetPermissionTab: false })

        const successfulPayload: any = {
          isLoadingConfig: false,
        }

        if (!this.isMultiJigsShare) {
          successfulPayload.jigId = this.jigMetadata.Id
        } else {
          // successfulPayload.hideAdvancedPermissionTab = true
        }

        this.$emit('on-editor-update-successful', successfulPayload)
      })
    }
  }

  private async inviteViewersOrEditors() {
    const createBulkInviteReq: any = {
      requests: [],
    }
    let editorEmail = ''

    editorEmail = this.currentEditor

    if (this.editorToInvite) {
      editorEmail = this.editorToInvite
      const resourcesToEdit = []
      for (const jid of this.jigIds) {
        resourcesToEdit.push({
          resourceHashID: jid,
          resourceType: AppConst.PermissionsResourceType.Jig,
          permActions: [
            PermissionActions.View, PermissionActions.Edit,
          ],
        })
      }
      const editorInvite = {
        inviteeEmail: this.editorToInvite,
        inviterUserID: this.$auth0.uid,
        tenantID: TenantHelpers.InvalidTenantID, // Set by the action
        inviteeRole: TenantHelpers.RoleGuestViewer1,
        resources: resourcesToEdit,
        app: 'jigspace',
      }
      const inviteCreateRequest = {
        invitation: editorInvite,
      }
      createBulkInviteReq.requests.push(inviteCreateRequest)
    }

    // Create request payloads for viewer invites
    if (this.viewersToInvite.length > 0) {
      const resources = []
      for (const jid of this.jigIds) {
        resources.push({
          resourceHashID: jid,
          resourceType: AppConst.PermissionsResourceType.Jig,
          permActions: [
            PermissionActions.View,
          ],
        })
      }
      for (const email of this.viewersToInvite) {
        // We don't need to send the editor an additional invite...
        if (editorEmail === email) {
          continue
        }
        const inv = {
          inviteeEmail: email,
          inviterUserID: this.$auth0.uid,
          tenantID: TenantHelpers.InvalidTenantID, // Set by the action
          inviteeRole: TenantHelpers.RoleGuestViewer1,
          resources,
          app: 'jigspace',
        }
        const inviteCreateRequest = {
          invitation: inv,
        }
        createBulkInviteReq.requests.push(inviteCreateRequest)
      }
    }

    this.$emit('on-update-jig-share-config', { permissionsDirty: true })
    await this.inviteCreateBulk(createBulkInviteReq)

    if (this.apiError !== '') {
      this.$emit('on-permission-update-error', {
        isLoadingConfig: false,
      })
    }

    // When creating more than 1 invite (bulk invites) - the API will return a 201 if 1 or more invitations got
    // created successfully and potentially errors for the other nested requests. If we find some errors, we
    // report them on the "modalMode.InviteResults" mode. Otherwise we exit the invite flow.
    this.extractFailedInvites()

    if (this.failedInvites.length > 0) {
      this.$emit('on-invite-failed', {
        promptKey: 'failedInvites',
        failedInvitesCopy: this.craftFailedInvitesCopy()
      })
    } else if (!this.isMultiJigsShare) {
      // this.mode = modalMode.AccessAndRoles
      // The invite approach doesn't reload permissions, so we must do that here now
      // await this.callbackLoad()
      this.$emit('on-permission-update-callback', () => {
        this.loadJigDetails({ resetPermissionTab: false })

        this.$emit('on-invite-successful', {
          editorEmail,
          viewersEmails: this.viewersToInvite,
          isLoadingConfig: false,
        })
      })
    } else {
      // We sent bulk invites with no failures, disable loading status
      this.$emit('on-bulk-invite-successful', {
        isLoadingConfig: false,
        hideAdvancedPermissionTab: true,
        closeModal: true,
      })
    }
  }

  private async onUpdateUserPermission(payload: any) {
    const { user, permission } = payload

    this.$emit('on-permission-update-start', {
      isLoadingConfig: true,
    })

    switch (permission) {
      case 'delete':
        await this.deleteUserPermission(user)
        return
      case 'edit':
        if (this.isMultiJigsShare) {
          this.editorToInvite = user.email
          await this.inviteViewersOrEditors()
        } else {
          await this.updateEditor(user)
        }
        return
      default:
        this.viewersToInvite.push(user.email)
        await this.inviteViewersOrEditors()
        return
    }
  }

  private extractFailedInvites() {
    this.failedInvites = []

    if (this.createBulkResponse.responses === undefined ||
      this.createBulkResponse.responses === null) {
      return
    }

    for (const obj of this.createBulkResponse.responses) {
      const item = obj

      if (item.errorMsg.length > 0) {
        this.failedInvites.push(obj)
      }
    }
  }

  private craftFailedInvitesCopy() {
    let copy = '<p>Following invitations have failed.</p><ul>'
    this.failedInvites.forEach((item: any, index: number) => {
      copy = `${copy}<li class="invite__item">${item.response.invitation.inviteeEmail}: ${item.response.invitation.status}</li>`

      if (index === this.failedInvites.length - 1) {
        copy = `${copy}</ul>`
      }
    })

    return copy
  }

  private ctaTrackingEvent() {
    segmentEventTracking('SharingAccessPanel_UpgradePanelClicked', {
      tenantName: this.myTenant.Name,
      tenantId: this.myTenant.ID,
      jigName: this.jigMetadata.ProjectName,
      jigId: this.jigMetadata.Id,
    })
  }
}
