
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})

export default class CalloutBannerVue extends Vue {
  @Prop({ type: Boolean, default: false })
  public isButtonCta!: boolean
  @Prop({ type: Boolean, default: true })
  public isPrimaryTheme!: boolean
  @Prop({ type: String, default: '' })
  public calloutCopy!: string
  @Prop({ type: String, default: '' })
  public ctaCopy!: string
  @Prop({ type: String, default: '/' })
  public ctaUrl!: string
  @Prop({ type: Function, default: () => {} })
  public ctaTracking!: Function

  private onCtaClick() {
    this.$emit('on-cta-click')
  }

  // Bind @click.native to <router-link> tag will not work, hence changing to manual router change.
  private onCtaLinkClick() {
    (this.ctaTracking as Function)()
    this.$router.push({ path: this.ctaUrl })
    this.$emit('on-cta-link-click')
  }
}
