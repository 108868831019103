
import { Tenant } from '@/store/modules/app/types'
import { AppConst } from '@/store/modules/constants'
import { JigMetaData } from '@/store/modules/jig/types'
import { Namespace, StandardObject } from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({})

export default class ShareUserItemVue extends Vue {
  @Prop({ type: Array, default: () => ([]) })
  public orderedTenantUsers!: any[]
  @Prop({ type: Object, default: () => ({}) })
  public jigOwnerPermission!: StandardObject
  @Prop({ type: Object, default: () => ({}) })
  public isConfigSelectActiveValue!: StandardObject
  @Prop({ type: Boolean, default: false })
  public isJigManagable!: boolean
  @Prop({ type: Boolean, default: false })
  public isMultiJigsShare!: boolean
  @Prop({ type: String, default: '' })
  public currentEditor!: string

  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('jigMetadata', { namespace: Namespace.Jig })
  public jigMetadata!: JigMetaData

  private isConfigSelectActive: StandardObject = {}
  private configList: StandardObject = {
    view: {
      icon: 'Icon_View.svg',
      text: 'Can view',
    },
    edit: {
      icon: 'Icon_Edit.svg',
      text: 'Can edit',
    },
    delete: {
      icon: 'Icon_Bin.svg',
      text: 'Remove access'
    },
  }
  private permissionToCURDMap: StandardObject = {
    U: 'edit',
    R: 'view',
  }

  @Watch('isConfigSelectActiveValue')
  private onIsConfigSelectActiveValueChanged(val: StandardObject) {
    this.isConfigSelectActive = { ...val }
  }

  private get isOrgTier(): boolean {
    return this.myTenant.Subscription && this.myTenant.Subscription.TierType === AppConst.Subscription.subscriptionTierOrganization
  }

  private get maxUserSeats(): number {
    const seatsIncluded: number = this.myTenant.Subscription && typeof this.myTenant.Subscription.SeatsIncludedInTier === 'number' ? this.myTenant.Subscription.SeatsIncludedInTier : 0
    const additionalSeats: number = this.myTenant.Subscription && this.myTenant.Subscription.AdditionalSeats ? this.myTenant.Subscription.AdditionalSeats : 0

    return seatsIncluded + additionalSeats
  }

  private userAvatarColor(index: number) {
    return AppConst.userAvatarColors[index % 4]
  }

  private userEmail(user: any) {
    return `${user.email}${user.roles.includes(TenantHelpers.RoleGuestViewer1) ? ' (Guest)' : ''}`
  }

  private userInitial(name: string) {
    return name.substring(0, 1)
  }

  private userConfigList(user: any) {
    const userConfigOptions: any = { ...this.configList }

    if (user.email === this.jigOwnerPermission.AudName || this.isMultiJigsShare) {
      delete userConfigOptions.delete
    }

    if (
      (this.currentEditor === user.email && !this.isMultiJigsShare) ||
      user.roles.includes(TenantHelpers.RoleGuestViewer1)
    ) {
      delete userConfigOptions.edit
    }

    return userConfigOptions
  }

  private toggleSelectField(user: any) {
    if (!this.isJigManagable) {
      return
    }

    this.isConfigSelectActive = {
      [user.Uid]: !this.isConfigSelectActive[user.Uid],
    }
  }

  private userJigPermission(user: any) {
    if (user.CRUD && user.CRUD.U) {
      // Can edit
      return this.configList[this.permissionToCURDMap.U].text
    } else if (user.CRUD && user.CRUD.R) {
      // Can view
      return this.configList[this.permissionToCURDMap.R].text
    } else {
      return 'Give access'
    }
  }

  private onUpdateUserPermission(user: any, permission: string|any, toggleSelect: boolean = true) {
    if (!this.isJigManagable) {
      return
    }

    if (toggleSelect) {
      this.toggleSelectField(user)
    }

    this.$emit('on-update-user-permission', { user, permission })
  }
}
