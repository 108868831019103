<!--<script lang="ts">
import { Line, mixins } from 'vue-chartjs'
import { Vue, Component, Prop } from 'vue-property-decorator'

const { reactiveProp } = mixins

@Component({
  extends: Line,
  mixins: [reactiveProp],
})
export default class StatLineChart extends Vue {
   public renderChart!: (chartData: any, options: any) => void

  @Prop() protected chartData: any

  @Prop({
    default() {
      return {}
    },
  }) protected options!: object

  public mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
</script>-->

<script lang="js">
import { Line, mixins } from 'vue-chartjs'

const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],
  mounted() {
    // To make a nice gradient, we have to access the $refs.canvas this chart uses to draw itself
    // so it must be done within this component, not a parent.
    // https://hackernoon.com/creating-stunning-charts-with-vue-js-and-chart-js-28af584adc0a
    var gradient = this.$refs.canvas.getContext('2d')
      .createLinearGradient(0, 0, 0, 450)
    gradient.addColorStop(0, 'rgba(0, 216, 255, 0.4)')
    gradient.addColorStop(0.5, 'rgba(0, 216, 255, 0.2)')
    gradient.addColorStop(0.75, 'rgba(0, 216, 255, 0)')
    gradient.addColorStop(1, 'rgba(0, 216, 255, 0)')
    this.chartData.datasets.forEach(dataset => {
      dataset.backgroundColor = gradient
    })
    this.renderChart(this.chartData, this.options)
  },
}
</script>
